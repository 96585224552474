<template>
  <div>
    <collapse-card
      :model-value="true"
      :show-collapse-button="false"
      disable-collapse
      class="w-full"
    >
      <template #title>
        <span class="mr-2 text-sm font-medium">
          Auto Assignment Rule
        </span>
      </template>
      <template #cardContent>
        <div class="py-2 px-4">
          <div class="flex mb-3">
            <icon-button
              class="mr-3 w-7.5 h-7.5"
              icon="icons/plus.svg"
              active
              active-btn-class="bg-custom-green-1 bg-opacity-20"
              active-icon-class="text-custom-green-1"
              @click="handleAddNewRule()"
            />
            <icon-button
              class="w-7.5 h-7.5"
              icon="icons/x.svg"
              :active="isDeleteSkuRuleBtnActive"
              active-btn-class="bg-primary-red bg-opacity-20"
              active-icon-class="text-primary-red"
              @click="deleteRule()"
            />
          </div>
          <brand-assurance-table
            v-model="skuData.tableData"
            :are-columns-sortable="false"
            root-element-class="mb-10"
          >
            <template
              v-for="(sku, skuIndex) in skuData.tableData.data"
              :key="'cell-slot' + skuIndex + '8'"
              #[generateTableSlotName(skuIndex,8)]
            >
              <base-button
                variant="btn-link"
                text="View/Edit"
                @click="editSKURule(sku)"
              />
            </template>
          </brand-assurance-table>
          <div class="flex mb-4">
            <base-button
              variant="btn-primary"
              :disabled="!isSaveSkuRuleBtnActive"
              class="mr-4"
              text="Save"
              @click="saveSkuRule()"
            />
            <base-button
              variant="btn-link"
              text="Cancel"
              @click="cancelSkuForm()"
            />
          </div>
          <div class="grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
            <base-select
              v-model="skuRuleFormData.submissionType"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Submission Type"
              label-class="label-w-46"
              :options="submissionTypeOptions"
              show-default-option
            />
            <base-input
              v-model="skuRuleFormData.licenseeId"
              type="text"
              container-class="flex"
              label-class="label-w-46"
              label="Licensee Id"
            />
            <base-select
              v-model="skuRuleFormData.styleGuide"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Style Guide"
              label-class="label-w-46"
              :options="styleGuideOptions"
              show-default-option
            />
            <base-select
              v-model="skuRuleFormData.pressReleaseCategory"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Press Release Category"
              label-class="label-w-46"
              :options="pressReleaseCategoryOptions"
              show-default-option
            />
            <base-select
              v-model="skuRuleFormData.materialSupplied"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Material Supplied"
              label-class="label-w-46"
              :options="materialSuppliedOptions"
              show-default-option
            />
            <base-input
              v-model="skuRuleFormData.articleId"
              type="text"
              container-class="flex"
              label="Article ID"
              label-class="label-w-46"
              disabled
            />
            <base-select
              v-model="skuRuleFormData.legalReviewer"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Legal Reviewer"
              label-class="label-w-46"
              :options="userOptions"
              show-default-option
            />
            <base-select
              v-model="skuRuleFormData.legalBackup"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Legal Backup Reviewer"
              label-class="label-w-46"
              :options="userOptions"
              show-default-option
            />
            <base-select
              v-model="skuRuleFormData.creativeReviewer"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Creative Reviewer"
              label-class="label-w-46"
              :options="userOptions"
              show-default-option
            />
            <base-select
              v-model="skuRuleFormData.creativeBackup"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Creative Backup"
              label-class="label-w-46"
              :options="userOptions"
              show-default-option
            />
          </div>
        </div>
      </template>
    </collapse-card>
  </div>
</template>

<script>
import { reactive, computed, defineAsyncComponent, ref, watch } from 'vue';
import useBaTable from '@/hooks/baTable.js';

export default {
    name: 'AutoAssignment',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue'))
    },

    setup () {
        const skuData = reactive({
            tableData: {
                columns: [
                    {
                        label: 'Submission Type',
                        key: 'submissionType'
                    },
                    {
                        label: 'Style Guide',
                        key: 'styleGuide'
                    },
                    {
                        label: 'Licensee ID',
                        key: 'licenseeId'
                    },
                    {
                        label: 'Legal Reviewer',
                        key: 'legalReviewer'
                    },
                    {
                        label: 'Legal Backup',
                        key: 'legalBackup'
                    },
                    {
                        label: 'Creative Reviewer',
                        key: 'creativeReviewer'
                    },
                    {
                        label: 'Creative Backup',
                        key: 'creativeBackup'
                    },
                    {
                        label: 'View or Edit',
                        key: ''
                    }
                ],
                data: [
                    {
                        id: 'sub000000',
                        submissionType: 'Standard',
                        styleGuide: 'Avengers',
                        licenseeId: '7815',
                        legalReviewer: 'userxyz',
                        legalBackup: 'testuserabc',
                        creativeReviewer: 'useryy',
                        creativeBackup: 'testuserabc',
                        pressReleaseCategory: 'Category A',
                        materialSupplied: 'Material A',
                        articleId: '1026'
                    },
                    {
                        id: 'sub000001',
                        submissionType: 'Standard',
                        styleGuide: 'Avengers',
                        licenseeId: '7815',
                        legalReviewer: 'userxyz',
                        legalBackup: 'testuserabc',
                        creativeReviewer: 'useryy',
                        creativeBackup: 'testuserabc',
                        pressReleaseCategory: 'Category A',
                        materialSupplied: 'Material A',
                        articleId: '1026'
                    },
                    {
                        id: 'sub000002',
                        submissionType: 'Standard',
                        styleGuide: 'Avengers',
                        licenseeId: '7815',
                        legalReviewer: 'userxyz',
                        legalBackup: 'testuserabc',
                        creativeReviewer: 'useryy',
                        creativeBackup: 'testuserabc',
                        pressReleaseCategory: 'Category A',
                        materialSupplied: 'Material A',
                        articleId: '1026'
                    },
                    {
                        id: 'sub000003',
                        submissionType: 'Standard',
                        styleGuide: 'Avengers',
                        licenseeId: '7815',
                        legalReviewer: 'userxyz',
                        legalBackup: 'testuserabc',
                        creativeReviewer: 'useryy',
                        creativeBackup: 'testuserabc',
                        pressReleaseCategory: 'Category A',
                        materialSupplied: 'Material A',
                        articleId: '1026'
                    }
                ]
            }
        });
        const selectedSkuRules = computed(() => skuData.tableData.data && skuData.tableData.data.filter(tableObj => tableObj.selected));
        const isDeleteSkuRuleBtnActive = computed(() => selectedSkuRules.value.length > 0);
        const handleAddNewRule = () => {
            resetSkuFormData();
        };
        const deleteRule = () => {
            skuData.tableData.data = skuData.tableData.data.filter(tableObj => !tableObj.selected);
        };
        const editMode = ref(false);
        const editSKURule = (skuRule) => {
            skuRuleFormData.value = { ...skuRule };
            editMode.value = true;
        };
        const isSaveSkuRuleBtnActive = computed(() => {
            // const formValues = Object.values(skuRuleFormData);
            // return formValues.filter(el => el).length === formValues.length;
            return skuRuleFormData.value.submissionType &&
            skuRuleFormData.value.styleGuide &&
            skuRuleFormData.value.licenseeId &&
            skuRuleFormData.value.legalReviewer &&
            skuRuleFormData.value.legalBackup &&
            skuRuleFormData.value.creativeReviewer &&
            skuRuleFormData.value.creativeBackup &&
            skuRuleFormData.value.pressReleaseCategory &&
            skuRuleFormData.value.materialSupplied &&
            skuRuleFormData.value.articleId;
        });
        const saveSkuRule = () => {
            if (editMode.value && skuRuleFormData.value.id) {
                skuData.tableData.data = skuData.tableData.data.map(skuRule => skuRule.id === skuRuleFormData.value.id ? { ...skuRuleFormData.value } : skuRule);
            } else {
                skuData.tableData.data.push({
                    ...skuRuleFormData.value,
                    id: 'sub0000' + (Math.ceil(Math.random() * 10000000))
                });
            }
        };
        const cancelSkuForm = () => {
            resetSkuFormData();
        };
        const { generateTableSlotName } = useBaTable();

        const skuRuleFormData = ref({
            submissionType: '',
            styleGuide: '',
            licenseeId: '',
            legalReviewer: '',
            legalBackup: '',
            creativeReviewer: '',
            creativeBackup: '',
            pressReleaseCategory: '',
            materialSupplied: '',
            articleId: ''
        });
        const resetSkuFormData = () => {
            skuRuleFormData.value = {
                submissionType: '',
                styleGuide: '',
                licenseeId: '',
                legalReviewer: '',
                legalBackup: '',
                creativeReviewer: '',
                creativeBackup: '',
                pressReleaseCategory: '',
                materialSupplied: '',
                articleId: ''
            };
            editMode.value = false;
        };

        const submissionTypeOptions = ref([
            'Standard',
            'Generic',
            'Press Releases, Blogs, Media Alerts, any other PR',
            'Interactive',
            'OPA Submission',
            'Print on Demand Art'
        ]);

        const styleGuideOptions = ref([
            'Avengers',
            'Spider Man',
            'Guardians of the Galaxy',
            'Ultimate Spider Man',
            'Avengers Infinity War',
            'Black Panther',
            'Deadpool',
            'Spider Man Home Coming'
        ]);

        const pressReleaseCategoryOptions = ref([
            'Category A',
            'Category B',
            'Category C',
            'Category D'
        ]);

        const materialSuppliedOptions = ref([
            'Material A',
            'Material B',
            'Material C',
            'Material D'
        ]);
        watch(
            () => skuRuleFormData.value.materialSupplied,
            () => {
                if (!skuRuleFormData.value.articleId) {
                    skuRuleFormData.value.articleId = '1026';
                }
                if (!skuRuleFormData.value.materialSupplied) {
                    skuRuleFormData.value.articleId = '';
                }
            }
        );
        const userOptions = ref([
            'userxyz',
            'testuserabc',
            'useryy'
        ]);

        return {
            skuData,
            isDeleteSkuRuleBtnActive,
            generateTableSlotName,
            skuRuleFormData,
            handleAddNewRule,
            deleteRule,
            editSKURule,
            isSaveSkuRuleBtnActive,
            saveSkuRule,
            submissionTypeOptions,
            styleGuideOptions,
            pressReleaseCategoryOptions,
            materialSuppliedOptions,
            userOptions,
            cancelSkuForm
        };
    }
};
</script>
